const currencies = {
  "en-US": "USD",
  "es-CL": "CLP",
  "es-MX": "MXN",
  "pt-BR": "BRL",
};

export const moneyFormat = (
  value: number,
  locale: string = "es-CL",
  currency?: string
): string => {
  if (isNaN(value)) return `$${0}`;

  if (currency) {
    if (currency === "UF") {
      return `UF ${new Intl.NumberFormat(locale).format(value)}`;
    }

    if (locale === "es-MX") {
      return `$ ${currency} ${new Intl.NumberFormat(locale).format(value)}`;
    }

    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency ? currency : currencies[locale],
    }).format(value);
  } else {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencies[locale],
    }).format(value);
  }
};
