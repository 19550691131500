import { API_HOST } from "@/app/client/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { SignUpRequest, LoginRequest, Address } from "@/dto/auth";
import { Response } from "@/dto/general";
import { getTokenFromStore, getUserToken } from "@/utils/auth";
import { CategoryGoPage } from "./categories";
import { DraftLead } from "../dto/draftLead";
import { Lead } from "../dto/lead";

export const userApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["User", "Addresses"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/customers/v100`,
    prepareHeaders: (headers) => {
      const token = getUserToken() || getTokenFromStore();
      if (token) headers.set("Authorization", `Token ${token}`);
      headers.set("content-type", "application/json");
      return headers;
    },
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getMe: builder.query<any, void>({
      query: () => `/me`,
    }),
    updateMe: builder.mutation({
      query: (payload) => ({
        url: `/me`,
        method: "PATCH",
        body: payload,
      }),
    }),
    updateOrganization: builder.mutation({
      query: (payload) => ({
        url: `/me/organization`,
        method: "PUT",
        body: payload,
      }),
    }),
    getLeads: builder.query<
      any,
      {
        goPage: CategoryGoPage | null;
        filters: any;
      }
    >({
      query: ({ goPage, filters }) => {
        const params = new URLSearchParams();

        const urlBase = `/leads`;
        if (!goPage) return urlBase;
        if (goPage.cursor) params.append("cursor", goPage.cursor);
        if (goPage.isPrevious) params.append("previous", "");
        if (goPage.page_size)
          params.append("page_size", goPage.page_size.toString());
        if (goPage.order_by) params.append("order_by", goPage.order_by);
        Object.keys(filters).map((key) => {
          params.append(key, filters[key]);
        });

        return `${urlBase}?${params.toString()}`;
      },
    }),
    getAddresses: builder.query<Address[], void>({
      query: () => `/addresses`,
      providesTags: ["Addresses"],
    }),
    postAddress: builder.mutation<Address, Address>({
      query: (payload) => ({
        url: `/addresses`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Addresses"],
    }),
    deleteAddress: builder.mutation({
      query: (id) => ({
        url: `/addresses/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Addresses"],
    }),
    updateAddress: builder.mutation<Address, Address>({
      query: (payload) => ({
        url: `/addresses/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Addresses"],
    }),
    updatePrimaryAddress: builder.mutation({
      query: (id) => ({
        url: `/addresses/${id}/set-primary`,
        method: "POST",
      }),
      invalidatesTags: ["Addresses"],
    }),
    getLeadDetail: builder.query<any, { id: number }>({
      query: ({ id }) => `/leads/${id}`,
    }),
    postShortLead: builder.mutation({
      query: (payload) => ({
        url: `/leads`,
        method: "POST",
        body: payload,
      }),
    }),
    postSaleLead: builder.mutation({
      query: (payload) => ({
        url: `/sale-leads`,
        method: "POST",
        body: payload,
      }),
    }),
    closeUserDraftLead: builder.mutation<
      DraftLead,
      { locale: string; uuid: string; lead: Lead }
    >({
      query: ({ locale, uuid, lead }) => ({
        url: `${API_HOST}/public/v101/${locale}/draft-leads/${uuid}/close`,
        method: "POST",
        body: lead,
      }),
    }),
  }),
});

export const {
  useGetMeQuery,
  useUpdateMeMutation,
  useUpdateOrganizationMutation,
  useGetAddressesQuery,
  usePostAddressMutation,
  useUpdateAddressMutation,
  useUpdatePrimaryAddressMutation,
  useDeleteAddressMutation,
  useGetLeadsQuery,
  useGetLeadDetailQuery,
  usePostShortLeadMutation,
  usePostSaleLeadMutation,
  useCloseUserDraftLeadMutation,
} = userApi;
