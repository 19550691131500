import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch, SetStateAction } from "react";

import type { AppState } from "../../app/store";

export interface LeadModalState {
  isModalOpen: boolean;
  modalType: string;
  productModal: number | null;
  categoryID: number | null;
  draftLeadUUID: string | null;
  setIsSuccessModalOpen: Dispatch<SetStateAction<boolean>> | null;
  showLoginOnModal: boolean;
}

const initialState: LeadModalState = {
  isModalOpen: false,
  modalType: "",
  productModal: null,
  categoryID: null,
  draftLeadUUID: null,
  setIsSuccessModalOpen: null,
  showLoginOnModal: false,
};

export const leadModalSlice = createSlice({
  name: "leadModal",
  initialState,
  reducers: {
    handleActionModal: (
      state,
      action: PayloadAction<Dispatch<SetStateAction<boolean>>>
    ) => {
      state.setIsSuccessModalOpen = action.payload;
    },
    handleLeadModal: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    handleProductModal: (state, action: PayloadAction<number | null>) => {
      state.productModal = action.payload;
    },
    handleDraftModal: (state, action: PayloadAction<string | null>) => {
      state.draftLeadUUID = action.payload;
    },
    handleModalType: (state, action: PayloadAction<any>) => {
      state.modalType = action.payload.type as string;
      state.categoryID = action.payload.category as number;
    },
    handleShowLoginOnModal: (state, action: PayloadAction<any>) => {
      state.showLoginOnModal = action.payload;
    },
  },
});

export const {
  handleLeadModal,
  handleProductModal,
  handleModalType,
  handleDraftModal,
  handleShowLoginOnModal,
  handleActionModal,
} = leadModalSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectShowLoginOnModal = (state: AppState) =>
  state.leadModal.showLoginOnModal;
export const selectIsOpenLeadModal = (state: AppState) =>
  state.leadModal.isModalOpen;
export const getModalType = (state: AppState) => state.leadModal.modalType;
export const getCategoryID = (state: AppState) => state.leadModal.categoryID;
export const getDraftLeadUUID = (state: AppState) =>
  state.leadModal.draftLeadUUID;
export const setIsSuccessModalOpen = (state: AppState) =>
  state.leadModal.setIsSuccessModalOpen;

export default leadModalSlice.reducer;
