import { Category } from "@/app/dto/category";
import { DraftLead } from "@/app/dto/draftLead";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { AppState } from "../../app/store";

export interface UpdateItemInCart {
  draftLeadId: string | null;
  draftLeadDetailId: string | null;
  draftLead: DraftLead | null;
}

export interface AddToCartState {
  isModalOpen: boolean;
  modalType: string;
  productModal: Category | null;
  updateItemInCart: UpdateItemInCart | null;
  showNotificationModal: boolean;
}

const initialState: AddToCartState = {
  isModalOpen: false,
  modalType: "",
  productModal: null,
  updateItemInCart: null,
  showNotificationModal: false,
};

export const addToCartSlice = createSlice({
  name: "addToCart",
  initialState,
  reducers: {
    handleAddToCartModal: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    handleNotificationModal: (state, action: PayloadAction<boolean>) => {
      state.showNotificationModal = action.payload;
    },
    handleUpdateItemModal: (
      state,
      action: PayloadAction<UpdateItemInCart | null>
    ) => {
      state.updateItemInCart = action.payload;
    },
    handleProductAddToCartModal: (
      state,
      action: PayloadAction<Category | null>
    ) => {
      state.productModal = action.payload;
    },
    handleAddToCartModalType: (state, action: PayloadAction<string>) => {
      state.modalType = action.payload;
    },
  },
});

export const {
  handleAddToCartModal,
  handleProductAddToCartModal,
  handleNotificationModal,
  handleAddToCartModalType,
  handleUpdateItemModal,
} = addToCartSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectIsOpenAddToCartModal = (state: AppState) =>
  state.addToCart.isModalOpen;
export const selectShowNotificationModal = (state: AppState) =>
  state.addToCart.showNotificationModal;
export const productModal = (state: AppState) => state.addToCart.productModal;
export const updateItemInCart = (state: AppState) =>
  state.addToCart.updateItemInCart;

export default addToCartSlice.reducer;
