import { Testimonial } from "@/app/dto/clients";
import Image from "next/image";

interface Props {
  testimonial: Testimonial;
}

export default function Testimony({ testimonial }: Props) {
  return (
    <div>
      <div className="rounded-l-md rounded-tr-md bg-white p-8 relative mt-10 text-lg">
        <div className="h-28 w-28 absolute -top-10 right-2">
          <Image layout="fill" objectFit="contain" alt="" src="/quotes.svg" />
        </div>
        <p>“{testimonial.testimonial}”</p>
      </div>
      <div className="flex justify-end mt-4">
        <div className="text-right">
          <strong>{testimonial.author}</strong>
          <p>{testimonial.position}</p>
          <p className="font-semibold">{testimonial.company}</p>
        </div>
        <div className="relative w-12 h-12 rounded-md ml-4">
          <Image
            src={testimonial.company_logo}
            alt={testimonial.company}
            layout="fill"
            objectFit="contain"
          />
        </div>
      </div>
    </div>
  );
}
