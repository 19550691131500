import { API_HOST } from "@/app/client/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { getTokenFromStore, getUserToken } from "@/utils/auth";
import { Checkout } from "../dto/checkout";

export const checkoutApi = createApi({
  reducerPath: "checkoutApi",
  tagTypes: ["Checkout"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/public/v101`,
    prepareHeaders: (headers) => {
      const token = getUserToken() || getTokenFromStore();
      if (token) headers.set("Authorization", `Token ${token}`);
      headers.set("content-type", "application/json");
      return headers;
    },
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getCheckout: builder.query<Checkout, { uuid: string }>({
      query: ({ uuid }) => `/checkout/${uuid}`,
    }),
    postCheckout: builder.mutation({
      query: (payload) => ({
        url: `/checkout/${payload.uuid}`,
        method: "POST",
        body: payload,
      }),
    }),
    getPayment: builder.query<any, { uuid: string }>({
      query: ({ uuid }) => `/payment/${uuid}`,
    }),
  }),
});

export const {
  useGetCheckoutQuery,
  usePostCheckoutMutation,
  useGetPaymentQuery,
} = checkoutApi;
