import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch, SetStateAction } from "react";

import type { AppState } from "../../app/store";

export interface ContactModalState {
  isModalOpen: boolean;
  modalType: string;
  productModal: number | null;
  categoryID: number | null;
  draftLeadUUID: string | null;
  setIsSuccessModalOpen: Dispatch<SetStateAction<boolean>> | null;
}

const initialState: ContactModalState = {
  isModalOpen: false,
  modalType: "",
  productModal: null,
  categoryID: null,
  draftLeadUUID: null,
  setIsSuccessModalOpen: null,
};

export const contactModalSlice = createSlice({
  name: "contactModal",
  initialState,
  reducers: {
    handleActionModal: (
      state,
      action: PayloadAction<Dispatch<SetStateAction<boolean>>>
    ) => {
      state.setIsSuccessModalOpen = action.payload;
    },
    handleContactModal: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    handleProductModal: (state, action: PayloadAction<number | null>) => {
      state.productModal = action.payload;
    },
    handleDraftModal: (state, action: PayloadAction<string | null>) => {
      state.draftLeadUUID = action.payload;
    },
    handleModalType: (state, action: PayloadAction<any>) => {
      state.modalType = action.payload.type as string;
      state.categoryID = action.payload.category as number;
    },
  },
});

export const {
  handleContactModal,
  handleProductModal,
  handleModalType,
  handleDraftModal,
  handleActionModal,
} = contactModalSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectIsOpenContactModal = (state: AppState) =>
  state.contactModal.isModalOpen;
export const getModalType = (state: AppState) => state.contactModal.modalType;
export const getCategoryID = (state: AppState) => state.contactModal.categoryID;
export const getDraftLeadUUID = (state: AppState) =>
  state.contactModal.draftLeadUUID;
export const setIsSuccessModalOpen = (state: AppState) =>
  state.contactModal.setIsSuccessModalOpen;

export default contactModalSlice.reducer;
