import classNames from "@/utils/classNames";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useState } from "react";

export default function Collapse({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={classNames(
          isOpen ? "bg-safety-orange" : " hover:bg-gray-100",
          "px-4 py-5 sm:px-6 hover:cursor-pointer"
        )}
      >
        <div className="w-full flex justify-between">
          <h3>{title}</h3>
          {isOpen ? (
            <ChevronUpIcon className="h-[24px]" />
          ) : (
            <ChevronDownIcon className="h-[24px]" />
          )}
        </div>
      </div>
      {isOpen && <div className="px-4 py-5 sm:p-6">{children}</div>}
    </div>
  );
}
