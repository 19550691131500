import { API_HOST } from "@/app/client/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { Client, Testimonial } from "@/dto/clients";
import { GeoUnit } from "@/services/categories";

interface GeounitPayload {
  locale?: string | null;
  parent_id?: number | null;
}

export const clientsApi = createApi({
  reducerPath: "clientsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/public/v100/`,
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getClients: builder.query<Client, string>({
      query: (locale) => `${locale}/clients`,
    }),

    getSuppliers: builder.query<Client, string>({
      query: (locale) => `${locale}/clients?is_supplier=True`,
    }),

    getTestimonials: builder.query<Testimonial, string>({
      query: (locale) => `${locale}/testimonials`,
    }),
    getRegions: builder.query<GeoUnit[], string>({
      query: (locale) => `${locale}/geo-units`,
    }),
    getCities: builder.query<GeoUnit[], GeounitPayload>({
      query: (data) => `${data.locale}/geo-units?parent_id=${data.parent_id}`,
    }),
  }),
});

export const {
  useGetRegionsQuery,
  useGetCitiesQuery,
  useGetClientsQuery,
  useGetTestimonialsQuery,
  useGetSuppliersQuery,
  util: { getRunningOperationPromises },
} = clientsApi;
export const { getClients, getTestimonials, getSuppliers } =
  clientsApi.endpoints;
