import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDraftUUID } from "../feature/auth";
import { AppState } from "../store";

export interface AppSliceState {
  uuid: string | null;
}

const initialState: AppSliceState = {
  uuid: null,
};

export const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    setUUID: (state, action: PayloadAction<string | null>) => {
      state.uuid = action.payload;
    },
  },
});

export const { setUUID } = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.draftLeads.value)`
export const uuidCart = (state: AppState) => state.appSlice.uuid;

export default appSlice.reducer;
