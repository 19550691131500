export function GoogleFields() {
  return (
    <div>
      <input
        type="hidden"
        name="lastSourceAttribution"
        id="lastSourceAttribution"
      />
      <input
        type="hidden"
        name="firstSourceAttribution"
        id="firstSourceAttribution"
      />
      <input
        type="hidden"
        name="multiSourceAttribution"
        id="multiSourceAttribution"
      />
      <input type="hidden" name="fbclid" id="fbclid" />
      <input type="hidden" name="gclid" id="gclid" />
      <input type="hidden" name="utm_source" id="utm_source" />
      <input type="hidden" name="utm_medium" id="utm_medium" />
      <input type="hidden" name="utm_campaign" id="utm_campaign" />
      <input type="hidden" name="utm_content" id="utm_content" />
      <input type="hidden" name="utm_term" id="utm_term" />
      <input type="hidden" name="client_user_agent" id="client_user_agent" />
      <input type="hidden" name="client_ip_address" id="client_ip_address" />
      <input type="hidden" name="analyticsClientId" id="analyticsClientId" />
    </div>
  );
}
