import { DraftLead } from "@/app/dto/draftLead";
import { Budget } from "@/app/dto/lead";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppState } from "../app/store";

interface budgetTimer {
  minutes: number;
  seconds: number;
}

interface UpdateDraftLead {
  draftLead: DraftLead;
  draftLeadId: string;
}

export interface CartState {
  draftLeads: DraftLead[];
  latestBudget: Budget | null;
  timer: budgetTimer | null;
  latestBudgetStart: string | null;
}

const initialState: CartState = {
  draftLeads: [],
  latestBudget: null,
  timer: null,
  latestBudgetStart: null,
};

export const cartListSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    setLatestBudgetStart: (state, action: PayloadAction<string>) => {
      state.latestBudgetStart = action.payload;
    },
    setLatestBudget: (state, action: PayloadAction<Budget>) => {
      state.latestBudget = action.payload;
    },
    setBudgetTimer: (state, action: PayloadAction<budgetTimer>) => {
      state.timer = action.payload;
    },
    setDraftLeads: (state, action: PayloadAction<DraftLead[]>) => {
      state.draftLeads = action.payload;
    },
    deleteDraftLead: (state, action: PayloadAction<number>) => {
      const index = state.draftLeads.findIndex(
        (item) => item.id === action.payload
      );
      if (index != -1) state.draftLeads.splice(index, 1);
    },
    updateDraftLead: (state, action: PayloadAction<UpdateDraftLead>) => {
      const index = state.draftLeads.findIndex(
        (item) => item.id === action.payload.draftLead.id
      );
      if (index != -1) state.draftLeads[index] = action.payload.draftLead;
    },
  },
});

export const {
  setBudgetTimer,
  setLatestBudgetStart,
  setLatestBudget,
  setDraftLeads,
  deleteDraftLead,
  updateDraftLead,
} = cartListSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.draftLeads.value)`
export const draftLeads = (state: AppState) => state.cartList.draftLeads;
export const selectLatestBudget = (state: AppState) =>
  state.cartList.latestBudget;
export const selectBudgetTimer = (state: AppState) => state.cartList.timer;
export const selectLatestBudgetStart = (state: AppState) =>
  state.cartList.latestBudgetStart;

export default cartListSlice.reducer;
