import classNames from "@/utils/classNames";
import { useMediaQuery } from "@/utils/useMediaQuery";
import Image from "next/image";

export default function Hero({
  title,
  subtitle,
  imageUrl,
  imageMobileUrl,
  imageAlt,
  imageWidth,
  imageHeight,
  imageWidthMobile = 523,
  imageHeightMobile = 400,
  btnText,
  btnFunction,
  arrowAnchor,
  children = null,
  smallHero = false,
}) {
  const isBreakpoint = useMediaQuery(720);

  const arrowFunction = (e) => {
    let anchor = document.getElementById(arrowAnchor);
    e.preventDefault(); // Stop Page Reloading
    anchor && anchor.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <header className="relative md:bg-[url('/landing/hero-bg-2.svg')] sm:bg-cover bg-no-repeat bg-hero-mobile sm:bg-bottom">
      <div
        className={classNames(
          smallHero ? "px-8 md:px-0 lg:max-w-7xl" : "max-w-[90rem]",
          "bg-[url('/landing/hero-bg-mobile.svg')] bg-full sm:bg-cover bg-no-repeat md:bg-none lg:flex justify-between mx-auto items-center  w-full lg:px-4 pt-6"
        )}
      >
        <div
          className={classNames(smallHero ? "" : "w-[70%] mx-auto lg:w-4/12")}
        >
          <h1 className="text-alice-blue text-center lg:text-left text-2xl sm:text-3xl font-extrabold">
            {title}
          </h1>
          <h2 className="text-alice-blue text-center lg:text-left sm:text-2xl sm:font-semibold sm:mt-5">
            {subtitle}
          </h2>
          {isBreakpoint && (
            <div
              onClick={arrowFunction}
              className="animate-bounce mt-4 text-center"
            >
              <Image
                width={44}
                height={44}
                src="/landing/arrow-down.svg"
                alt=""
              />
            </div>
          )}
        </div>
        <div
          className={classNames(
            smallHero
              ? "hidden sm:block w-[200px] mx-auto lg:w-[380px]"
              : "w-full lg:w-7/12",
            ""
          )}
        >
          <div className="relative w-full">
            {isBreakpoint ? (
              <Image
                unoptimized={true}
                width={imageWidthMobile}
                height={imageHeightMobile}
                layout="responsive"
                src={imageMobileUrl}
                alt={imageAlt}
              />
            ) : (
              <Image
                width={imageWidth}
                height={imageHeight}
                layout="responsive"
                src={imageUrl}
                alt={imageAlt}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          smallHero ? "mb-8" : "mb-14",
          "max-w-[90rem] mx-auto px-4"
        )}
      >
        {children && (
          <div
            className={classNames(
              arrowAnchor === "used-sale" ? "hidden lg:block" : "",
              smallHero ? "mt-4 md:mt-0" : "",
              "bg-white shadow-lg p-4 rounded-2xl"
            )}
          >
            {children}
          </div>
        )}
      </div>
    </header>
  );
}
