import { API_HOST } from "@/app/client/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { Client, Testimonial } from "@/dto/clients";
import { GeoUnit } from "@/services/categories";
import { GEO_API_KEY } from "../dto/geo";

interface GeounitPayload {
  locale?: string | null;
  parent_id?: number | null;
}

export const geoApi = createApi({
  reducerPath: "geoApi",
  baseQuery: fetchBaseQuery({}),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getGeoFromAddress: builder.query<any, string>({
      query: (placeId) =>
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GEO_API_KEY}`,
    }),
  }),
});

export const {
  useGetGeoFromAddressQuery,
  util: { getRunningOperationPromises },
} = geoApi;
