import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { AppState } from "../../app/store";

export interface CartItem {
  id: number;
  name: string;
  type: string;
  fromDate: string | null;
  daysCount: number | null;
}

export interface RemoveAndClearModal {
  open: boolean,
  type: string,
  draftLeadDetailId: number | null
  draftLeadId: string | null
}

export interface CartState {
  removeAndClearModal: RemoveAndClearModal;
  modalType: string;
  productModal: number | null;
  items: CartItem[];
}

const initialState: CartState = {
  removeAndClearModal: {
    open: false,
    type: '',
    draftLeadDetailId: null,
    draftLeadId: null
  },
  modalType: "",
  productModal: null,
  items: [
    {
      id: 1,
      name: "Electric articulated arm 12 meters",
      type: "lease",
      fromDate: "2020-01-07",
      daysCount: 25,
    },
    {
      id: 2,
      name: "Electric articulated arm 12 meters",
      type: "buy",
      fromDate: null,
      daysCount: null,
    },
  ],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    handleRemoveEmptyModal: (
      state,
      action: PayloadAction<RemoveAndClearModal>
    ) => {
      state.removeAndClearModal = action.payload;
    },
    handleEmptyCart: (state) => {
      state.items = [];
    },
    handleRemoveItem: (state, action: PayloadAction<string>) => {
      state.modalType = action.payload;
    },
    handleAddItem: (state, action: PayloadAction<CartItem[]>) => {
      state.items = action.payload;
    },
  },
});

export const { handleRemoveEmptyModal, handleEmptyCart, handleRemoveItem } =
  cartSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectItems = (state: AppState) => state.cart.items;
export const selectRemoveAndClearModal = (state: AppState) =>
  state.cart.removeAndClearModal;

export default cartSlice.reducer;
