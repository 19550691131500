import { API_HOST } from "../client/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { AutomaticBudgetValidation, Lead, LeadSale } from "@/dto/lead";
import { Coupon } from "@/dto/coupons";
import { AttentionHour } from "@/dto/attentionHour";
import { DraftLead, Request } from "@/dto/draftLead";

interface LeadRequest {
  locale: string;
  uuid: string;
}

interface ContactRequest {
  organization_name: string;
  token: string;
  phone: string;
  email: string;
  name: string;
  geo_unit_id: number;
}
interface CouponRequest {
  locale: string;
  code: string;
}

export const leadsApi = createApi({
  reducerPath: "leadsApi",
  tagTypes: ["Lead"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/public/v101/`,
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getLeadDetails: builder.query<DraftLead[], LeadRequest>({
      providesTags: ["Lead"],
      query: ({ locale, uuid }) => `${locale}/draft-leads/${uuid}/details`,
    }),
    checkAutomaticBudget: builder.mutation<
      Request,
      { locale: string; lead: AutomaticBudgetValidation }
    >({
      query: ({ locale, lead }) => ({
        url: `${locale}/check-automatic-budget`,
        method: "POST",
        body: lead,
      }),
    }),
    createLead: builder.mutation<any, { locale: string; lead: Lead }>({
      query: ({ locale, lead }) => ({
        url: `${locale}/leads`,
        method: "POST",
        body: lead,
      }),
      invalidatesTags: ["Lead"],
    }),
    createSaleLead: builder.mutation<any, { locale: string; lead: LeadSale }>({
      query: ({ locale, lead }) => ({
        url: `${locale}/sale-leads`,
        method: "POST",
        body: lead,
      }),
      invalidatesTags: ["Lead"],
    }),
    createSupplierLead: builder.mutation<
      any,
      { token: string; locale: string; lead: Lead }
    >({
      query: ({ token, locale, lead }) => ({
        url: `${locale}/supplier-lead`,
        method: "POST",
        body: { ...lead, token },
      }),
    }),
    createDraftLead: builder.mutation<
      DraftLead,
      { locale: string; draftLead: DraftLead }
    >({
      query: ({ locale, draftLead }) => ({
        url: `${locale}/draft-leads`,
        method: "POST",
        body: draftLead,
      }),
      invalidatesTags: ["Lead"],
    }),
    closeDraftLead: builder.mutation<
      DraftLead,
      { locale: string; uuid: string; lead: Lead }
    >({
      query: ({ locale, uuid, lead }) => ({
        url: `${locale}/draft-leads/${uuid}/close`,
        method: "POST",
        body: lead,
      }),
      invalidatesTags: ["Lead"],
    }),
    addDraftLead: builder.mutation<
      DraftLead,
      { locale: string; uuid: string; draftLead: DraftLead }
    >({
      query: ({ locale, uuid, draftLead }) => ({
        url: `${locale}/draft-leads/${uuid}/details`,
        method: "POST",
        body: draftLead,
      }),
      invalidatesTags: ["Lead"],
    }),
    updateDraftLead: builder.mutation<
      DraftLead,
      {
        locale: string;
        draftLeadId: string;
        draftLeadDetailId: string;
        draftLead: DraftLead;
      }
    >({
      query: ({ locale, draftLeadId, draftLeadDetailId, draftLead }) => ({
        url: `${locale}/draft-leads/${draftLeadId}/details/${draftLeadDetailId}`,
        method: "PUT",
        body: draftLead,
      }),
      invalidatesTags: ["Lead"],
    }),
    deleteDraftLead: builder.mutation<
      any,
      { locale: string; draftLeadId: string; draftLeadDetailId: number }
    >({
      query: ({ locale, draftLeadId, draftLeadDetailId }) => ({
        url: `${locale}/draft-leads/${draftLeadId}/details/${draftLeadDetailId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Lead"],
    }),
    acceptLead: builder.mutation<Request, { uuid: string }>({
      query: ({ uuid }) => `/accept-budget/${uuid}`,
    }),
    getAttentionHours: builder.query<AttentionHour[], string>({
      query: (locale) => `${locale}/attention-hours`,
    }),
    validateCoupon: builder.mutation<Coupon, CouponRequest>({
      query: ({ locale, code }) => `${locale}/coupons/${code}`,
    }),
    contact: builder.mutation<any, { payload: ContactRequest }>({
      query: ({ payload }) => ({
        url: `/contact`,
        method: "POST",
        body: payload,
      }),
    }),
    newsletterSubscriber: builder.mutation<any, { email: string }>({
      query: (payload) => ({
        url: `${API_HOST}/public/v100/newsletter/subscribe`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetLeadDetailsQuery,
  useCheckAutomaticBudgetMutation,
  useCreateLeadMutation,
  useGetAttentionHoursQuery,
  useCreateDraftLeadMutation,
  useAddDraftLeadMutation,
  useDeleteDraftLeadMutation,
  useCloseDraftLeadMutation,
  useUpdateDraftLeadMutation,
  useCreateSupplierLeadMutation,
  useValidateCouponMutation,
  useContactMutation,
  useCreateSaleLeadMutation,
  useNewsletterSubscriberMutation,
  useAcceptLeadMutation,
  util: { getRunningOperationPromises },
} = leadsApi;
export const {} = leadsApi.endpoints;
