import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch, SetStateAction } from "react";

import type { AppState } from "../../app/store";

export interface NavLocaleAlertState {
  isModalOpen: boolean;
  locale: string | null;
  reason: string;
}

const initialState: NavLocaleAlertState = {
  isModalOpen: false,
  reason: "",
  locale: null,
};

export const navLocaleAlertSlice = createSlice({
  name: "navLocaleAlert",
  initialState,
  reducers: {
    handleActionModal: (state, action: PayloadAction<NavLocaleAlertState>) => {
      state.reason = action.payload.reason;
      state.isModalOpen = action.payload.isModalOpen;
      state.locale = action.payload.locale;
    },
  },
});

export const { handleActionModal } = navLocaleAlertSlice.actions;

export const isOpenLocaleAlert = (state: AppState) =>
  state.navLocaleAlert.isModalOpen;
export const getLocaleAlert = (state: AppState) => state.navLocaleAlert.locale;
export const getReasonAlert = (state: AppState) => state.navLocaleAlert.reason;

export default navLocaleAlertSlice.reducer;
