import { API_HOST } from "../client/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  Category,
  CategoryFilter,
  CategoryFilterSelected,
  SearchResults,
} from "../dto/category";
import { HYDRATE } from "next-redux-wrapper";
import { GoPage, ListPageResults } from "@/dto/requests";
import { ProductForSale } from "@/dto/product";

export const enum CategoryApiConstants {
  CATEGORY_TYPE_SEARCH = "searched",
  CATEGORY_TYPE_WON = "lead_won",
  CATEGORY_TYPE_LEASE = "lead_count",
}

export interface GeoUnit {
  id: number;
  name: string;
  parent_id: number | null;
  parent: GeoUnit | null;
  country_id: string | null;
}

export interface CategoryGoPage extends GoPage {
  order_by?: string;
  page_size?: number;
  filters?: CategoryFilterSelected[] | null;
  parent_ids?: string[] | null;
}

interface FiltersRequest {
  locale: string;
  parentId?: string[] | null;
}

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/public/v100/`,
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getGeoUnits: builder.query<GeoUnit[], { locale: string; parent?: number }>({
      query: ({ locale, parent }) =>
        `${locale}/geo-units${parent ? `?parent_id=${parent}` : ""}`,
    }),
    getCategories: builder.query<
      Category[],
      {
        locale: string;
        parent: string | null;
        order_by: string | null;
        page_size: number | null;
        only_children: boolean | null;
        only_for_sale?: boolean | null;
      }
    >({
      query: ({
        locale,
        parent,
        order_by,
        only_children,
        page_size,
        only_for_sale,
      }) => {
        const params = new URLSearchParams();
        if (parent) params.append("parent_id", parent);
        if (order_by) params.append("order_by", order_by);
        if (page_size) params.append("page_size", `${page_size}`);
        if (only_children) params.append("only_children", `${only_children}`);
        if (only_children) params.append("only_children", `${only_children}`);
        if (only_for_sale) params.append("only_for_sale", `${only_for_sale}`);
        return `${locale}/categories?${params.toString()}`;
      },
    }),
    getSearch: builder.query<SearchResults, { locale: string; query: string }>({
      query: ({ locale, query }) =>
        `${API_HOST}/public/v101/${locale}/search?query=${query}`,
    }),
    getCategoryDetail: builder.query<Category, { locale: string; id: string }>({
      query: ({ locale, id }) => `${locale}/categories/${id}`,
      transformResponse: (response: Category) => {
        return {
          ...response,
          specs: Object.keys(response.attributes).map((key) => ({
            name: key,
            value: response.attributes[key],
          })),
        };
      },
    }),
    getCategoryFilters: builder.query<CategoryFilter[], FiltersRequest>({
      query: ({ locale, parentId }) => {
        const params = new URLSearchParams();
        parentId?.forEach((id) => params.append("parent_ids", id));
        return `${locale}/category-filters?${params.toString()}`;
      },
    }),
    getHomeFeaturedCategories: builder.query<Category[], string>({
      query: (locale) => `${locale}/featured-home-categories`,
    }),
    getCategoriesChild: builder.query<
      ListPageResults<Category>,
      {
        locale: string;
        goPage: CategoryGoPage | null;
      }
    >({
      query: ({ locale, goPage }) => {
        const params = new URLSearchParams();

        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.page_size)
            params.append("page_size", goPage.page_size.toString());
          if (goPage.order_by) params.append("order_by", goPage.order_by);
          if (goPage.parent_ids) {
            goPage.parent_ids?.forEach((element) => {
              params.append("parent_id", element);
            });
          }

          if (goPage.filters?.length > 0) {
            goPage.filters.forEach((filter) => {
              params.append(`attributes__${filter.name}`, filter.value);
            });
          }
        }
        return `${locale}/child-categories?${params.toString()}`;
      },
    }),
    getSupplierProductsForSale: builder.query<
      ListPageResults<ProductForSale>,
      {
        locale: string;
        goPage: CategoryGoPage | null;
        filters: any;
      }
    >({
      query: ({ locale, goPage, filters }) => {
        const params = new URLSearchParams();

        const urlBase = `${locale}/supplier-products-for-sale`;
        if (!goPage) return urlBase;
        if (goPage.cursor) params.append("cursor", goPage.cursor);
        if (goPage.isPrevious) params.append("previous", "");
        if (goPage.page_size)
          params.append("page_size", goPage.page_size.toString());
        if (goPage.order_by) params.append("order_by", goPage.order_by);
        Object.keys(filters).map((key) => {
          if (key === "categories") {
            filters[key].forEach((cat) => {
              params.append("categories", cat);
            });
          } else {
            params.append(key, filters[key]);
          }
        });

        return `${urlBase}?${params.toString()}`;
      },
    }),
    getSupplierProductsForSaleDetail: builder.query<
      ProductForSale,
      { locale: string; id: string }
    >({
      query: ({ locale, id }) => `${locale}/supplier-products-for-sale/${id}`,
    }),
    getSupplierProductsForSaleRelated: builder.query<
      ProductForSale,
      { locale: string; id: string }
    >({
      query: ({ locale, id }) =>
        `${locale}/supplier-products-for-sale/${id}/related`,
    }),
    getSupplierProductsForSaleRecommended: builder.query<
      any,
      { locale: string }
    >({
      query: ({ locale }) => `${locale}/supplier-products-for-sale-recommended`,
    }),
  }),
});

export const {
  useGetGeoUnitsQuery,
  useGetCategoriesQuery,
  useGetSearchQuery,
  useGetCategoryDetailQuery,
  useGetCategoriesChildQuery,
  useGetCategoryFiltersQuery,
  useGetSupplierProductsForSaleQuery,
  useGetSupplierProductsForSaleDetailQuery,
  util: { getRunningOperationPromises },
} = categoriesApi;
export const {
  getCategories,
  getCategoriesChild,
  getHomeFeaturedCategories,
  getCategoryDetail,
  getSupplierProductsForSaleDetail,
  getSupplierProductsForSaleRelated,
  getSupplierProductsForSaleRecommended,
  getSupplierProductsForSale,
} = categoriesApi.endpoints;
