import React, { useEffect, useRef, useState } from "react";
import Head from "next/head";
import { t } from "@lingui/macro";
import { GoogleFields } from "./GoogleFields";
import { useSelector } from "react-redux";
import { selectIsOpenLeadModal } from "@/features/leadModal/leadModalSlice";

import dynamic from "next/dynamic";
import {
  selectIsOpenAddToCartModal,
  selectShowNotificationModal,
} from "@/features/addToCart/addToCartSlice";
import { isOpenLocaleAlert } from "@/features/navLocaleAlert/NavLocaleAlertSlice";
import useOnScreen from "@/utils/useOnScreen";
import BudgetTimer from "../Leads/BudgetTimer";
import { selectLatestBudget } from "@/features/cartSlice";
import { useRouter } from "next/router";
const Nav = dynamic(() => import("./Nav/Nav"));
const Footer = dynamic(() => import("./Footer"));
const LeadModal = dynamic(() => import("@/features/leadModal/LeadModal"));
const ChatCTA = dynamic(() => import("./ChatCTA"));
const AddToCartModal = dynamic(() => import("@/features/addToCart/AddToCart"));
const AddToCartNotification = dynamic(
  () => import("@/features/addToCart/Modal")
);
const NavLocaleAlert = dynamic(
  () => import("../../features/navLocaleAlert/NavLocaleAlert")
);

export const Layout = ({ children }) => {
  const isOpenLeadModal = useSelector(selectIsOpenLeadModal);
  const isOpenAddToCartModal = useSelector(selectIsOpenAddToCartModal);
  const isOpenNotificationModal = useSelector(selectShowNotificationModal);
  const isOpenLocaleAlertModal = useSelector(isOpenLocaleAlert);
  const latestBudget = useSelector(selectLatestBudget);

  const ref = useRef();
  const divIsVisible = useOnScreen(ref);
  const [isVisible, setIsVisible] = useState(false);

  const { pathname } = useRouter();

  useEffect(() => {
    if (!isVisible && divIsVisible) {
      setIsVisible(divIsVisible);
    }
  }, [divIsVisible]);

  return (
    <>
      <Head>
        <title>RendaloMaq | {t`Rental and Sale of Machinery Online`}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Nav />
      <main className="bg-alice-blue">
        {children}
        {isOpenLeadModal && <LeadModal />}
        {isOpenAddToCartModal && <AddToCartModal />}
        {isOpenNotificationModal && <AddToCartNotification />}
        {isOpenLocaleAlertModal && <NavLocaleAlert />}
      </main>
      <div ref={ref} className="box">
        {isVisible && <Footer />}
      </div>
      <ChatCTA />
      <GoogleFields />
      {latestBudget && !pathname.includes("checkout") && (
        <BudgetTimer isInBudget={false} />
      )}
    </>
  );
};
