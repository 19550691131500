import { ReactElement, ReactNode, useEffect, useState } from "react";
import { Provider } from "react-redux";
import type { AppProps } from "next/app";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { useRouter } from "next/router";
import store, { wrapper } from "@/app/store";
import { Layout } from "@/components/Layout";
import NextNProgress from "nextjs-progressbar";

import "../styles/globals.css";
import "@fontsource/nunito-sans";
import "@fontsource/nunito-sans/600.css";
import "@fontsource/nunito-sans/700.css";
import "@fontsource/nunito-sans/800.css";
import "material-icons/iconfont/material-icons.css";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";

import { es, pt } from "make-plural/plurals";

import { messages } from "../translations/locales/es-CL/messages";
import ProvidersLanding from "./arrendar-mi-maquinaria";
import GoogleReCaptchaComponent from "@/components/Utils/GoogleReCaptchaComponent";
import dynamic from "next/dynamic";
import { NextPage } from "next/types";
import Script from "next/script";
import Head from "next/head";

const TagManagerComponent = dynamic(
  () => import("@/components/Utils/TagManagerComponent")
);

i18n.load("es-CL", messages);
i18n.activate("es-CL");

i18n.loadLocaleData({
  "pt-BR": { plurals: pt },
  "es-CL": { plurals: es },
  "es-MX": { plurals: es },
  pseudo: { plurals: es },
});

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { locale } = useRouter();

  const [isMounted, setIsMounted] = useState(false);
  const getLayout = Component.getLayout
    ? (page) => page
    : (page) => <Layout>{page}</Layout>;

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 3000);
  }, []);

  useEffect(() => {
    async function load(locale: any) {
      const { messages } = await import(
        `../translations/locales/${locale}/messages`
      );

      i18n.load(locale, messages);
      i18n.activate(locale);
    }

    load(locale);
  }, [locale]);

  return (
    <Provider store={store}>
      {isMounted && <TagManagerComponent />}
      <Head>
        <script
          id="vtag-ai-js"
          async
          src="https://r2.leadsy.ai/tag.js"
          data-pid="HCfZFjqJvGB2eM0H"
          data-version="062024"
        ></script>
      </Head>
      <NextNProgress color="#1f2937" />
      <I18nProvider i18n={i18n}>
        {getLayout(
          <>
            {
              /* Se puede mejorar */
              Component === ProvidersLanding ? (
                <GoogleReCaptchaComponent>
                  <Component {...pageProps} />
                </GoogleReCaptchaComponent>
              ) : (
                <Component {...pageProps} />
              )
            }
          </>
        )}
      </I18nProvider>
    </Provider>
  );
}

export default wrapper.withRedux(MyApp);
