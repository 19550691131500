import { User } from "@/app/dto/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppState } from "../app/store";

export interface AuthState {
  user: User | null;
}

const initialState: AuthState = {
  user: null,
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.draftLeads.value)`
export const user = (state: AppState) => state.authSlice.user;

export default authSlice.reducer;
