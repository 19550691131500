import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import leadReducer from "@/features/leadModal/leadModalSlice";
import contactReducer from "@/features/contactModal/contactModalSlice";
import addToCartReducer from "@/features/addToCart/addToCartSlice";
import cartReducer from "@/features/cart/cartSlice";
import { clientsApi } from "@/app/services/clients";
import { categoriesApi } from "./services/categories";
import { createWrapper } from "next-redux-wrapper";
import { leadsApi } from "@/services/leads";
import { cartListSlice } from "@/features/cartSlice";
import { navLocaleAlertSlice } from "@/features/navLocaleAlert/NavLocaleAlertSlice";
import { appSlice } from "@/slices/appSlice";
import { authApi } from "@/services/auth";
import { authSlice } from "@/features/authSlice";
import { userApi } from "./services/user";
import { geoApi } from "./services/geo";
import { checkoutApi } from "./services/checkout";

export function makeStore() {
  return configureStore({
    reducer: {
      [authApi.reducerPath]: authApi.reducer,
      [userApi.reducerPath]: userApi.reducer,
      [checkoutApi.reducerPath]: checkoutApi.reducer,
      [categoriesApi.reducerPath]: categoriesApi.reducer,
      [clientsApi.reducerPath]: clientsApi.reducer,
      [leadsApi.reducerPath]: leadsApi.reducer,
      [geoApi.reducerPath]: geoApi.reducer,
      leadModal: leadReducer,
      contactModal: contactReducer,
      addToCart: addToCartReducer,
      cart: cartReducer,
      cartList: cartListSlice.reducer,
      navLocaleAlert: navLocaleAlertSlice.reducer,
      appSlice: appSlice.reducer,
      authSlice: authSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(geoApi.middleware)
        .concat(authApi.middleware)
        .concat(userApi.middleware)
        .concat(categoriesApi.middleware)
        .concat(leadsApi.middleware)
        .concat(clientsApi.middleware),
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<typeof store.getState>;
export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
