import store from "@/app/store";

export function setUserCountry(country: string) {
  localStorage.setItem("user_country", country);
}

export function setUserToken(token: string) {
  localStorage.setItem("token", token);
}

export function getUserToken(): string | null {
  if (!window) return null;
  return localStorage.getItem("token");
}

export function removeUserCountry() {
  localStorage.removeItem("user_country");
}

export function removeUserToken() {
  localStorage.removeItem("token");
}

export function getTokenFromStore() {
  return store?.getState().authSlice?.user?.token;
}
