import { Trans } from "@lingui/macro";
import Image from "next/image";

interface Props {
  buttonFunction: (e: any) => void | null;
  btnText: string | null;
}

export default function ProvidersCTALanding({
  buttonFunction,
  btnText,
}: Props) {
  return (
    <div className="relative">
      <div
        className="hidden absolute top-0 inset-x-0 h-1/2 lg:block"
        aria-hidden="true"
      />
      <div className="max-w-7xl mx-auto bg-gummetal lg:bg-transparent">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-[50px] lg:bg-transparent">
            <div
              className="absolute inset-x-0 h-1/2 bg-gummetal lg:hidden"
              aria-hidden="true"
            />
            <div className="relative -top-10 lg:top-auto max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
              <div className="relative w-80 h-60">
                <Image
                  layout="fill"
                  className="object-cover object-center rounded-3xl shadow-2xl"
                  src="/Providers.png"
                  alt="Dos personas de pie usando vestimenta de protección"
                />
              </div>
            </div>
          </div>

          <div className="relative bg-gummetal lg:col-start-2 lg:row-start-1 lg:col-span-11 lg:rounded-3xl lg:grid lg:grid-cols-11 lg:items-center">
            <div className="relative max-w-md mx-auto py-12 px-4 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
              <h2
                className="text-2xl tracking-tight font-black text-white mb-7"
                id="join-heading"
              >
                <Trans>
                  Do you have machinery and want to make it profitable?
                </Trans>
              </h2>
              <p className="text-xl font-semibold text-white">
                ¡<Trans>Rent more, doing less</Trans>!
              </p>
              <br />
              <p className="text-xl font-semibold text-white">
                <Trans>From getting clients to handle guarantees</Trans>.
              </p>
              <button
                onClick={buttonFunction}
                className="btn-primary rounded-md mt-8 text-lg"
              >
                {btnText ? btnText : <Trans>Become a supplier now!</Trans>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
