import { Request } from "@/app/dto/draftLead";
import { useAcceptLeadMutation } from "@/app/services/leads";
import {
  selectBudgetTimer,
  selectLatestBudget,
  selectLatestBudgetStart,
  setBudgetTimer,
  setLatestBudget,
} from "@/features/cartSlice";
import { getLocale } from "@/utils/locale";
import { moneyFormat } from "@/utils/moneyFormat";
import { Trans } from "@lingui/macro";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BudgetModalTimer from "./BudgetModalTimer";

export default function BudgetTimer({ isInBudget }) {
  let interval;
  const dispatch = useDispatch();
  const router = useRouter();
  const locale = getLocale();
  const latestBudget = useSelector(selectLatestBudget);
  const start = new Date(useSelector(selectLatestBudgetStart));
  const deadlineCreator = new Date(start);
  const deadline = deadlineCreator.setMinutes(start.getMinutes() + 60);

  const [open, setOpen] = useState(false);
  const [over, setOver] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [acceptLead] = useAcceptLeadMutation();

  const handleTimer = () => {
    const time = deadline - Date.now();

    if (time <= 0) {
      setOver(true);
    }

    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  const handleAcceptBudget = async () => {
    const req = (await acceptLead({ uuid: latestBudget?.uuid })) as Request;

    if (!req.error) {
      setOpen(false);
      dispatch(setLatestBudget(null));
      router.push({
        pathname: `/${locale}/requirements/confirmation`,
      });
    }
  };

  useEffect(() => {
    if (latestBudget) interval = setInterval(() => handleTimer(), 1000);

    return () => clearInterval(interval);
  }, [latestBudget]);

  useEffect(() => {
    if (over) {
      dispatch(setBudgetTimer(null));
      clearInterval(interval);
    }
  }, [over]);

  if (!latestBudget || over) {
    return <></>;
  }

  if (isInBudget)
    return (
      <div className="bg-gummetal p-4 rounded-lg">
        <p className="sm:font-bold text-center text-white text-sm sm:text-regular">
          <span className="w-52 sm:w-auto inline-block">
            <Trans>Take advantage of this offer before time runs out!</Trans>
          </span>
        </p>
        <div className="flex items-center justify-center">
          <div className="text-white text-2xl sm:text-4xl font-black flex ">
            <div className="w-12 text-center">
              <span className="mb-0 block ">
                {`${minutes}`.padStart(2, "0")}
              </span>

              <small className="hidden sm:block text-xs block font-normal text-gray-200">
                <Trans>minutes</Trans>
              </small>
            </div>
            :
            <div className="w-12 text-center">
              <span className="mb-0 block pb-0">
                {`${seconds}`.padStart(2, "0")}
              </span>

              <small className="hidden sm:block block text-xs font-normal text-gray-200">
                <Trans>seconds</Trans>
              </small>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <>
      <div className="hidden sm:block sm:fixed z-30 w-[90%] max-w-4xl bottom-4 left-1/2 transform -translate-x-1/2">
        <div className=" bg-gummetal py-4 px-10 rounded-t-lg">
          <div className="flex gap-16 items-center text-gray-200 justify-between">
            <div className="flex gap-4 items-center">
              <div className="shrink-0 relative rounded-full bg-gray-100 h-14 w-14">
                <Image src="/products/2.png" alt="" layout="fill" />
              </div>
              <div>
                <p className="text-sm">
                  <Trans>Your latest quote is next to expire</Trans>
                </p>
                <h3 className="font-semibold text-white">
                  {latestBudget.details ? latestBudget.details[0].name : "--"}{" "}
                  <strong>({moneyFormat(latestBudget.total)})</strong>
                </h3>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={latestBudget.url}
                  className="underline text-xs block"
                >
                  Ver más
                </a>
              </div>
            </div>
            <div>
              <div className="text-white text-4xl font-black flex ">
                <div className="w-12 text-center">
                  <span className="mb-0 block ">
                    {`${minutes}`.padStart(2, "0")}
                  </span>

                  <small className="text-xs block font-normal text-gray-200">
                    <Trans>minutes</Trans>
                  </small>
                </div>
                :
                <div className="w-12 text-center">
                  <span className="mb-0 block pb-0">
                    {`${seconds}`.padStart(2, "0")}
                  </span>

                  <small className="block text-xs font-normal text-gray-200">
                    <Trans>seconds</Trans>
                  </small>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="shrink-0 mb-3 sm:mb-0 inline-flex justify-center rounded-md btn-primary background-animate"
              cy-data="submit-lead-btn"
              onClick={handleAcceptBudget}
            >
              <Trans>Im interested</Trans>
            </button>
          </div>
        </div>
        <div className="bg-gray-700 rounded-b-lg py-2">
          <p className="gradient-text drop-shadow-lg whitespace-pre-wrap text-center font-bold">
            <Trans>Take advantage of this offer before time runs out!</Trans>
          </p>
        </div>
      </div>

      <button className="sm:hidden" onClick={() => setOpen(true)}>
        <div className="fixed z-30 p-4 rounded-r-lg left-0 top-44 bg-gummetal">
          <div className="relative h-14 w-14">
            <div className="shrink-0 absolute rounded-full bg-gray-100 h-14 w-14">
              <Image src={latestBudget.image_url} alt="" layout="fill" />
            </div>
            <div className="absolute h-14 w-14 bg-black/30 rounded-full flex items-center justify-center">
              <div>
                <div className="text-white text-lg font-black flex ">
                  <div className="w-6 text-center">
                    <span className="mb-0 block ">
                      {`${minutes}`.padStart(2, "0")}
                    </span>
                  </div>
                  :
                  <div className="w-6 text-center">
                    <span className="mb-0 block pb-0">
                      {`${seconds}`.padStart(2, "0")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="gradient-text font-bold text-center mt-2">
            <Trans>Offer!</Trans>
          </p>
        </div>
      </button>

      <BudgetModalTimer
        open={open}
        setOpen={setOpen}
        budget={latestBudget}
        handleAcceptBudget={handleAcceptBudget}
      />
    </>
  );
}
