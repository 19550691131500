import Head from "next/head";

interface SEOData {
  description: string;
  keywords: string;
  h2: string;
  h3: string;
}

interface OpenGraph {
  title?: string;
  description?: string;
  image?: string;
}

interface Props {
  data?: SEOData;
  openGraph?: OpenGraph;
}

export default function SEOinPage({ data, openGraph }: Props) {
  return (
    <>
      <Head>
        <>
          {data && (
            <>
              <meta name="description" content={data.description} key="desc" />
              <meta name="keywords" content={data.keywords} />
            </>
          )}

          {openGraph && (
            <>
              <meta property="og:title" content={openGraph.title} />
              <meta property="og:description" content={openGraph.description} />
              <meta property="og:image" content={openGraph.image} />
            </>
          )}
        </>
      </Head>
      {data && (
        <div className="hidden">
          <>
            <h2>{data.h2}</h2>
            <h3>{data.h3}</h3>
          </>
        </div>
      )}
    </>
  );
}
