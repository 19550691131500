import { API_HOST } from "@/app/client/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import {
  SignUpRequest,
  LoginRequest,
  ResetPasswordRequest,
  ResetPasswordPayload,
} from "@/dto/auth";
import { Response } from "@/dto/general";

export const authApi = createApi({
  reducerPath: "authApi",
  tagTypes: ["Auth"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/customers/v100`,
  }),

  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    postLeadAndSignup: builder.mutation<Response, { payload: any }>({
      query: ({ payload }) => ({
        url: `/leads-and-register`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),
    postSignup: builder.mutation<Response, { payload: SignUpRequest }>({
      query: ({ payload }) => ({
        url: `/register-customer`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),
    postLogin: builder.mutation<
      Response,
      { locale: string; payload: LoginRequest }
    >({
      query: ({ payload }) => ({
        url: `${API_HOST}/token-auth/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Auth"],
    }),
    requestResetPassword: builder.mutation<
      void,
      { payload: ResetPasswordRequest }
    >({
      query: ({ payload }) => ({
        url: `${API_HOST}/public/v100/request-reset-password`,
        method: "POST",
        body: payload,
      }),
    }),
    resetPassword: builder.mutation<
      void,
      { code: string; payload: ResetPasswordPayload }
    >({
      query: ({ code, payload }) => ({
        url: `${API_HOST}/public/v100/change-password/${code}`,
        method: "PUT",
        body: payload,
      }),
    }),
    validateEmail: builder.mutation<Response, { payload: any }>({
      query: ({ payload }) => ({
        url: `${API_HOST}/users/v100/validate-email`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  usePostSignupMutation,
  usePostLeadAndSignupMutation,
  usePostLoginMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
  useValidateEmailMutation,
} = authApi;
export const { postSignup } = authApi.endpoints;
